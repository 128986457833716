import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
import _ from 'underscore';
import siteConfig from "@iso/config/site.config";
import history from '@iso/lib/helpers/history';

var jwtDecode = require('jwt-decode');

export function* changedCard() {
  yield takeEvery(actions.CHANGE_CARDS, function* () { });
}
//Get database product Info to redux
export function* initData() {
  let reduxCart = JSON.parse(localStorage.getItem('reduxCart'));
  //Redux send data
  yield put({
    type: actions.UPDATE_DATA,
    reduxCart,
  });
}
export function* updateData({ reduxCart }) {
  localStorage.setItem('reduxCart', JSON.stringify(reduxCart));
  yield put({
    type: actions.UPDATE_DATA,
    reduxCart,
  });

  //Redux product code and product amount send database
  const token = jwtDecode(localStorage.getItem("id_token"));
  if (typeof token === 'undefined') { return history.replace('/'); }
  const activeUser = localStorage.getItem("activeUser")
  let account = '';
  if ((token.urole === 'dealersv') || (token.urole === 'dealerwhouse') || (token.urole === 'dealerlimited') || (token.urole === 'dealersub')) { account = token.dcode; };

  if (typeof activeUser != 'undefined') { account = activeUser }
  const reqBody = { "items": reduxCart.items, "accountNo": account };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("id_token") || undefined
    },
    body: JSON.stringify(reqBody)
  };
  fetch(siteConfig.api.carts.postCart, requestOptions)
    .then(response => {
      if (!response.ok) (console.log(response.statusText));
      return response.json();
    })
    .then(data => {
    })
    .catch();
}
export default function* () {
  yield all([
    takeEvery(actions.INIT_DATA_SAGA, initData),
    takeEvery(actions.UPDATE_DATA_SAGA, updateData),
  ]);
}
