const actions = {
  INIT_DATA: 'ECOMMERCE_INIT_DATA',
  INIT_DATA_SAGA: 'ECOMMERCE_INIT_DATA_SAGA',
  UPDATE_DATA: 'ECOMMERCE_UPDATE_DATA',
  UPDATE_DATA_SAGA: 'ECOMMERCE_UPDATE_DATA_SAGA',
  CHANGE_VIEW: 'ECOMMERCE_CHANGE_VIEW',
  VIEW_TOPBAR_CART: 'ECOMMERCE_VIEW_TOPBAR_CART',
  initData: () => ({ type: actions.INIT_DATA_SAGA }),
  changeView: view => ({
    type: actions.CHANGE_VIEW,
    view,
  }),
  changeViewTopbarCart: viewTopbarCart => {
    return {
      type: actions.VIEW_TOPBAR_CART,
      viewTopbarCart,
    };
  },
  updateReduxCart: reduxCart => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.UPDATE_DATA_SAGA,
        reduxCart,
      });
    };
  },
  addToCart: (product, amount, isPartial, totalM2, subItemsAmount, groupingItemCode) => {
    return (dispatch, getState) => {
      const { reduxCart = {} } = getState().Ecommerce;
      const { items: reduxCartItems = [] } = reduxCart || {};
      const itemCode = product.itemCode;
      reduxCartItems.push({ itemCode, amount, isPartial, totalM2, subItemsAmount, groupingItemCode });
      reduxCart['items'] = reduxCartItems;
      dispatch({
        type: actions.UPDATE_DATA_SAGA,
        reduxCart,
      });
    };
  },
};
export default actions;
