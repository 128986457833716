// const apiUrl = 'http://localhost:5000/api/';
// const apiUrl = 'http://life-enerji-api.karyasmd.com/api/';
const apiUrl = 'https://scoop-api.life-climate.com/api/';

export default {
  siteName: 'SCOOP',
  siteIcon: 'ion-flash',
  footerText: `Karya SMD © ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
  nullOrEmptySearchItem: '(YOK)',
  api: {
    report: {
      postPeriods: apiUrl + 'customer/periods',
      getPeriods: apiUrl + 'customer/periods',
      getPeriodsSearch: apiUrl + 'customer/periods-search',
      getPeriodsByCustomerId: apiUrl + 'customer/periods',
      // getActivePeriodsById: apiUrl + 'customer/periods/active',
      deletePeriods: apiUrl + 'customer/periods',
      getPeriodById: apiUrl + 'customer/period',
      postApprove: apiUrl + 'customers/approve-status',
    },
    core: {
      getCountry: apiUrl + 'core/countries',
      getlevel0: apiUrl + 'core/level0s',
      saveEmissionStatements: apiUrl + 'core/versioning-factors',
      getEmissionStatementsByCategoryKod: apiUrl + 'core/emission-statements',
      getFuelTypes: apiUrl + 'core/fuel-types',
      getFuelTypesFiltered: apiUrl + 'core/fuel-types',
      getLevel2sByLevel1Kod: apiUrl + 'core/level2s',
      getUniqueVersions: apiUrl + 'core/unique-versions',
      getFuelTypeUnits: apiUrl + 'core/fuelTypes/unit'
    },
    customer: {
      deleteCustomer: apiUrl + 'customers/delete',
      searchCustomers: apiUrl + 'customers/search',
      getCustomers: apiUrl + 'customers',
      getCustomer: apiUrl + 'customers',
      getActiveCustomers: apiUrl + 'customers/active-search',
      postCustomer: apiUrl + 'customers',
      activityDataUpdate: apiUrl + 'customer/emissions/update',
      periodComparison: apiUrl + 'customer/periods/comparison',
      periodComparisonPie: apiUrl + 'customer/periods/comparison-pie',
      activePeriodDataCount: apiUrl + 'customer/periods/data-count',
      getActivityData: apiUrl + 'customer/emissions',
      saveActivityData: apiUrl + 'customer/emissions',
      delteActivityData: apiUrl + 'customer/emissions'
    },
    users: {
      postUsers: apiUrl + 'security/users/search',
      postUser: apiUrl + 'security/users',
      deleteUser: apiUrl + 'security/users/',
      getRestrictedUsers: apiUrl + 'security/restricted-users/search',
      postRestrictedUser: apiUrl + 'security/restricted-users',
      getActiveAuditors: apiUrl + 'security/auditor-users'
    },
    security: {
      postAccounts: apiUrl + 'core/accounts',
      getAccountsTree: apiUrl + 'core/accounts-tree',
      postAccountsExtended: apiUrl + 'core/accounts/extended',
      getNotificationType: apiUrl + 'security/notifications/types',
      getRoles: apiUrl + 'security/roles',
      getUser: apiUrl + 'security/users/',
      getCustomerUser: apiUrl + 'security/user-customer/',
      getAuditorUser: apiUrl + 'security/user-auditor/',
      postAuthenticate: apiUrl + 'security/authenticate',
      postChangePassword: apiUrl + 'security/change-password',
      getLogSources: apiUrl + 'security/logs/sources',
      getLogTypes: apiUrl + 'security/logs/types',
      postLog: apiUrl + 'security/logs/search',
      postSaveLog: apiUrl + 'security/logs',
      postForgotPassword: apiUrl + 'security/forgot-password',
      postNotification: apiUrl + 'security/notifications/search',
      getNotificationByUserId: apiUrl + 'security/users/{userId}/notifications',
      postIsRead: apiUrl + 'security/notifications/{notificationId}/read',
      getRestrictedRole: apiUrl + 'security/restricted-users/roles',
      getGenerateUsername: apiUrl + 'security/restricted-users/generate-username',
      getGeneratePassword: apiUrl + 'security/generate-password',
      putCacheRefresh: apiUrl + 'security/reset-cache',
    },
    image: {
      getProductsOfImages: apiUrl + 'images/products-for-images',
      getTypes: apiUrl + 'images/types',
      getProductImages: apiUrl + 'images/products/{productCode}/images',
      uploadImage: apiUrl + 'images/upload',
      deleteImage: apiUrl + 'images/delete/',
      updateProduct: apiUrl + 'images/products/update',
      updateImageInfo: apiUrl + 'images/update/info',
      updateOrder: apiUrl + 'images/update/order'
    },
    file: {
      postExcel: apiUrl + 'files/upload-excel',
      uploadFile: apiUrl + 'files/customers/upload',
      showFile: apiUrl + 'files/customers/download',
      getFilesSearch: apiUrl + 'files/search',
      deleteFileByGuidId: apiUrl + 'files'
    }

  },
  dateFormat: 'DD-MM-YYYY',
  dateFormatAddTime: 'DD-MM-YYYY HH:mm:ss',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};
